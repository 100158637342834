import React, { useState } from "react";
import axios from "axios";
const defaultData = {
    name: '',
    email: '',
    phone: '',
    message: ''
}
const defaultDataValid = {
    name: '',
    email: '',
    phone: '',
    message: ''
}
const ContactUs = () => {
    const [dataContact,setDataContact] = useState(defaultData),
        [dataContactValid,setDataContactValid] = useState(defaultDataValid),
        [isLoading,setIsLoading] = useState(false),
        [showSuccessMessage,setShowSuccessMessage] = useState("d-none"),
        [showErrorMessage,setShowErrorMessage] = useState("d-none"),
        [successMessage,setSuccessMessage] = useState(""),
        [errorMessage,setErrorMessage] = useState("")
        ;
    const handleData = (input) => {
        const key = input.target.id,
            value = input.target.value;            
        if(dataContactValid[key]!==undefined)
        {
            if(value&&dataContactValid[key])
            {
                setDataContactValid({
                    ...dataContactValid,
                    [key]: ''
                })
            }
        }
        setDataContact({
            ...dataContact,
            [key] : value
        });
    }
    const valid = () => {
        if(!dataContact.name){
            setDataContactValid({
                ...dataContactValid,
                name : "show-content"
            });
            return false;
        }
        if(!dataContact.email){
            setDataContactValid({
                ...dataContactValid,
                email : "show-content"
            });
            return false;
        }
        if(!dataContact.phone){
            setDataContactValid({
                ...dataContactValid,
                phone : "show-content"
            });
            return false;
        }
        if(!dataContact.message){
            setDataContactValid({
                ...dataContactValid,
                message : "show-content"
            });
            return false;
        }
        return true;
    }
    const save = async () => {
        if(!valid()){
            return false;
        }
        setIsLoading(true);
        try{
            const resource = await axios.post(process.env.REACT_APP_PATH_API + 'contact/by/customer',dataContact),
                data = await resource.data;            
            if(data.status){
                setShowSuccessMessage('');
                setSuccessMessage(data.message);

                setShowErrorMessage('d-none');
                setErrorMessage("");

                setDataContact(defaultData);
            } else {
                setShowSuccessMessage('d-none');
                setSuccessMessage("");

                setShowErrorMessage('');
                setErrorMessage(data.message);
            }
            setIsLoading(false);
            setTimeout(()=>{
                setShowSuccessMessage('d-none');
                setSuccessMessage('');
                setShowErrorMessage('d-none');
                setErrorMessage('');
            },5000);
        } catch(error){
            setIsLoading(false);
            setShowErrorMessage('');
            setErrorMessage('Internal Error, please try later');
        }
    }
    return (
        <>
            <header className="masthead" style={{color: '#FFF'}}>
                <div className="container px-5 text-center">
                    <h1 className="fw-bolder">Contact Me</h1>
                </div>
            </header>
            <section className="page-section" id="contact" style={{paddingTop: 0}}>
                <div className="container">
                    {/* <h2 className="page-section-heading text-center text-uppercase text-secondary mb-0">Contact Me</h2> */}
                    <div className="divider-custom">
                        <div className="divider-custom-line"></div>
                        <div className="divider-custom-icon">{/*<i className="fas fa-star"></i>*/}</div>
                        <div className="divider-custom-line"></div>
                    </div>
                    <div className="row justify-content-center">
                        <div className="col-lg-8 col-xl-7">
                            <form id="contactForm">
                                <div className="form-floating mb-3">
                                    <input className="form-control" id="name" type="text" placeholder="Enter your name..." data-sb-validations="required" value={dataContact.name} onChange={handleData} />
                                    <label>Full name</label>
                                    <div className={`invalid-feedback ${dataContactValid.name}`} data-sb-feedback="name:required">A name is required.</div>
                                </div>
                                <div className="form-floating mb-3">
                                    <input className="form-control" id="email" type="email" placeholder="name@example.com" data-sb-validations="required,email" value={dataContact.email} onChange={handleData} />
                                    <label>Email address</label>
                                    <div className={`invalid-feedback ${dataContactValid.email}`} data-sb-feedback="email:required">An email is required.</div>
                                </div>
                                <div className="form-floating mb-3">
                                    <input className="form-control" id="phone" type="tel" placeholder="000-000-0000" data-sb-validations="required" value={dataContact.phone} onChange={handleData} />
                                    <label>Phone number</label>
                                    <div className={`invalid-feedback ${dataContactValid.phone}`} data-sb-feedback="phone:required">A phone number is required.</div>
                                </div>
                                <div className="form-floating mb-3">
                                    <textarea className="form-control" id="message" type="text" placeholder="Enter your message here..." style={{height: '10rem'}} data-sb-validations="required" onChange={handleData} value={dataContact.message}></textarea>
                                    <label>Message</label>
                                    <div className={`invalid-feedback ${dataContactValid.message}`} data-sb-feedback="message:required">A message is required.</div>
                                </div>
                                <div className={`${showSuccessMessage}`} id="submitSuccessMessage">
                                    <div className="text-center mb-3" style={{color: '#12c541'}}>
                                        <div className="fw-bolder">Form submission successful!</div>
                                        {successMessage}
                                    </div>
                                </div>                            
                                <div className={`${showErrorMessage}`} id="submitErrorMessage">
                                    <div className="text-center text-danger mb-3">
                                        {errorMessage}
                                    </div>
                                </div>
                                {
                                    isLoading ? <button className="btn btn-primary btn-xl" type="button" disabled>
                                        Loading...
                                    </button>
                                    :<button className="btn btn-success btn-xl" id="submitButton" type="button" onClick={save}>
                                        Send my request
                                    </button>

                                }
                            </form>
                        </div>
                        <div className="col-lg-4 col-xl-5">
                            <br />
                            <h5>Dirección:
                            <br /> 
                            <small className="text-muted">Santiago, Santiago De Los Caballeros Dominican Republic</small>
                            </h5>
                            <br />
                            <h5>Correo electrónico:<br /> <small className="text-muted">polocigars@benedictocigars.com</small></h5>
                            <br />
                            <h5>Teléfono:<br /> <small className="text-muted">+1 (849) 863-6477</small></h5>
                            <br />
                            <h5>Redes sociales
                                <br />
                                <a href="https://web.whatsapp.com/send?phone=18493422903" target="_blank" rel="noreferrer" style={{color: '#000'}}>
                                    <i className="fab fa-whatsapp"></i>
                                </a>
                                <a href="https://www.instagram.com/benedicto.cigars.oficial/" target="_blank" rel="noreferrer" style={{marginLeft: '1rem',color: '#000'}}>
                                    <i className="fab fa-instagram"></i>
                                </a>
                                <i className="fab fa-facebook" style={{marginLeft: '1rem'}}></i>
                                <i className="fa-brands fa-twitter" style={{marginLeft: '1rem'}}></i>
                            </h5>
                        </div>
                    </div>
                </div>
            </section>
        </>        
    );
}
export default ContactUs;