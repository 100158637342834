import React,{useState} from "react";
import axios from 'axios';
const defaultData = {
    email: ""
}
const Badge = () => {
    const [dataSubscriber,setDataSubscriber] = useState(defaultData),
        [emptyEmail,setEmptyEmail] = useState(''),
        [invalidEmail,setInvalidEmail] = useState(''),
        [successStatus,setSuccessStatus] = useState(''),
        [successResponse,setSuccessResponse] = useState(''),
        [warningResponse,setWarningResponse] = useState(''),
        [isLoading,setIsLoading] = useState(false);
    const handleData = (input) => {
        const key = input.target.id,
            value = input.target.value;
        if(value&&(emptyEmail||invalidEmail)){
            setEmptyEmail('');
            setInvalidEmail('');
        }
        setDataSubscriber({
            ...dataSubscriber,
            [key] : value
        });
    }
    const save = async () => {
        if(!dataSubscriber.email){
            setEmptyEmail('display-on');
            return;
        } else {
            setEmptyEmail('');
        }
        setIsLoading(true);
        try {
            const resource = await axios.post(process.env.REACT_APP_PATH_API + 'save/subscriber',dataSubscriber),
                data = await resource.data;
            if(data.status){
                setEmptyEmail('');
                setInvalidEmail('');
                setSuccessStatus('display-on');
                setSuccessResponse(data.msg);
                setDataSubscriber(defaultData);
            } else {
                setSuccessStatus('');
                setInvalidEmail('display-on');                
                setWarningResponse(data.msg);
            }
            setIsLoading(false);
            setTimeout(()=>{
                setEmptyEmail('');
                setInvalidEmail('');
                setSuccessStatus('');
                setSuccessResponse('');
            },5000)
        } catch (error){
            setIsLoading(false);
            setInvalidEmail('display-on');                
            setWarningResponse("Internal Error, please contact the administrator");
        }
    }
    return (
        <section className="bg-black sc-custom" style={{paddingTop: '2rem',paddingBottom: '2rem'}}>
            <div className="container px-5">
                <div className="row">
                    <div className="col-md-6">
                        <h5 className="text-left text-white font-alt mb-4">JOIN THE FAMILY</h5>
                        <p className="text-white">Sign up to receive the latest from Benedicto Cigars.</p>
                    </div>
                    <div className="col-md-6">
                        <div className="input-group mb-3" style={{marginTop: '4%'}}>
                            <input 
                                type="text" 
                                id="email"
                                className="form-control" 
                                placeholder="name@email.com" 
                                aria-label="Recipient's username" 
                                aria-describedby="basic-addon2"
                                style={{background:'#000',color: '#fff'}}
                                value={dataSubscriber.email}
                                onChange={handleData} />
                            {
                                isLoading ? <span 
                                    className="input-group-text"
                                    style={{background:'#000',color: '#fff'}}
                                    >LOADING...</span>
                                :<span 
                                    id="submit-badge-button"
                                    className="input-group-text main-text-color"
                                    style={{background:'#000',color: '#fff'}}
                                    onClick={save}>SUBMIT</span>
                            }
                        </div>
                        <div className={'invalid-feedback mt-2 '+emptyEmail}>An email is required.</div>
                        <div className={'d-none ' +successStatus}>
                            <div className="text-center mb-3 mt-2" style={{color: '#1ae14e'}}>
                                <div className="fw-bolder">Successful!</div>
                                {successResponse}
                            </div>
                        </div>                        
                        <div className={'d-none '+invalidEmail}><div className="text-center text-danger mb-3 mt-2">{warningResponse}!</div></div>
                    </div>
                </div>
            </div>
        </section>
    );
}
export default Badge;