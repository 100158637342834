import React from "react";
const Feature = () => {
    return (
        <>
        <section className="sc-custom-2">
            <div className="container px-4">
                <div className="row gx-5 align-items-center">
                    <div className="col-lg-8 order-lg-1 mb-5">
                        <div className="container-fluid px-5">
                            <div className="row gx-5">
                                <div className="col-md-12">
                                    <div className="text-center">
                                        {/* <i className="bi-phone icon-feature text-gradient d-block mb-3"></i> */}
                                        <h3 className="display-5 main-text-color mb-3">Elaboration</h3>
                                        <p className="text-muted mb-0" style={{textAlign: 'justify'}}>
                                            Benedicto cigar has been made with top quality materials, selecting leaves carefully which can provide all flavors that characterizes a Benedicto.
                                            <br /><br />Among the vitolas available are:<br /><br />                                            
                                        </p>
                                        <ul style={{marginBottom: '0px'}}>
                                            <li style={{textAlign: 'left'}}>Toro 54x6.</li>
                                            <li style={{textAlign: 'left'}}>Robusto 50x5.</li>
                                            <li style={{textAlign: 'left'}}>Torpedo 50x6.</li>
                                        </ul>
                                    </div>
                                </div>
                                {/* <div className="col-md-6 mb-5">
                                    <div className="text-center">
                                        <i className="bi-camera icon-feature text-gradient d-block mb-3"></i>
                                        <h3 className="font-alt">Flexible Use</h3>
                                        <p className="text-muted mb-0">Put an image, video, animation, or anything else in the screen!</p>
                                    </div>
                                </div> */}
                            </div>
                            <div className="row">
                                <div className="col-md-12 mb-3">
                                    <div className="text-center">
                                        <h3 className="display-5 main-text-color">Blends</h3>
                                    </div>
                                </div>
                            </div>
                            <div className="row">   
                                <div className="col-md-6 mb-5">
                                    <div className="text-center">
                                        <h4 className="font-alt" style={{color: '#333'}}>Maduro</h4>
                                        <p className="text-muted mb-0" style={{textAlign: 'justify'}}>
                                            It's the stellar presentation of Benedicto with ascending strength between 6 to 7, this cigar is covered with a San Andres wrapper from Mexico, subjected to an arduous aging process whose "Dominican Negrito" wrapper harmonizes and provides an pleasure that identifies a Benedicto Maduro. Rated by master blends of the country.
                                        </p>
                                    </div>
                                </div>                             
                                <div className="col-md-6 mb-5">
                                    <div className="text-center">
                                        <h4 className="font-alt" style={{color: '#333'}}>Connecticut</h4>
                                        <p className="text-muted mb-0" style={{textAlign: 'justify'}}>
                                            This blend with ascending strength between 6 to 7 was achieved by dressing a Benedicto Maduro with the same binder, "Dominican Negrito", but with a Connecticut wrapper. This provides an intense flavor of nuts, typical of the process which the material is subjected. The main characteristic of this league is a clean sensation on your palate.
                                        </p>
                                    </div>
                                </div>
                            </div>
                            <div className="row">
                                <div className="col-md-12 mb-5">
                                    <div className="text-center">
                                        <h4 className="font-alt" style={{color: '#333'}}>Habano</h4>
                                        <p className="text-muted mb-0" style={{textAlign: 'justify'}}>
                                            The Abana wrappers that cover the Dominican-scented tobacco binder, provides smoothness in a blend rich in flavor that characterizes the Dominican-Cuban blends. This blend has an ascending strength of 5 to 6 with a pearly white ash.
                                        </p>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="col-lg-4 order-lg-0">
                        {/* <!-- Features section device mockup--> */}
                        <div className="features-device-mockup">
                            {/* eslint-disable-next-line jsx-a11y/img-redundant-alt */}
                            <img src="/assets/img/products/all.png" alt="Logo image" style={{width: '100%'}} />
                        </div>
                    </div>
                </div>
            </div>
        </section>
        <section className="bg-white sc-custom">
            <div className="container px-4">
                <div className="row gx-5 align-items-center justify-content-center justify-content-lg-between">
                    <div className="col-12 col-lg-5">
                        <h2 className="display-4 lh-1 mb-4">Smoking should never be a bad experience.</h2>
                        <p className="lead fw-normal text-muted mb-5 mb-lg-0" style={{textAlign: 'justify'}}>We know how important it is to have a good experience when enjoying a good cigar, that is why we always use top quality materials and the finest process in the manufacture of our products.</p>
                    </div>
                    <div className="col-sm-8 col-md-6">
                        {/* <div className="px-5 px-sm-0"><img className="img-fluid rounded" src="/assets/img/stlon.jpg" alt="..." style={{width: '100%'}} /></div> */}
                        <div className="px-5 px-sm-0"><img className="img-fluid rounded" src="/assets/img/bg-img-smoke.jpeg" alt="..." style={{width: '100%'}} /></div>
                    </div>
                </div>
            </div>
        </section>
        <section className="bg-white" style={{marginTop: '5rem',marginBottom: '5rem'}}>
            <div className="container px-3">
                <div className="row gx-5 align-items-center justify-content-center justify-content-lg-between">
                    <div className="col-md-6 mb-5">
                        <div className="px-5 px-sm-0"><img className="img-fluid rounded" src="/assets/img/features_3.jpg" alt="..." style={{width: '100%'}} /></div>
                    </div>
                    <div className="col-12 col-lg-5">
                        <h2 className="display-4 lh-1 mb-4">Makes the different with Benedicto Cigars.</h2>
                        <p className="lead fw-normal text-muted mb-5 mb-lg-0" style={{textAlign: 'justify'}}>Many years of inquiry to put a name in the highest level, one family, one trajectory and the prestige of those whom left their lives in fertile lands of tobacco located in northwest line of the Dominican Republic.</p>
                    </div>                    
                </div>
            </div>
        </section>
        </>
    );
}
export default Feature;