import React, {useState} from 'react';
import { useNavigate } from 'react-router-dom';
const Header = () => {
    const [toggle,setToggle] = useState(''),
        navigate = useNavigate();
    const navBarRedirect = (e) => {
        e.preventDefault();
        setToggle('');
        const href = e.currentTarget.getAttribute('href');
        navigate(href);
    }
    return (
        <>
            {/*<!-- Navigation-->*/}
            <nav className="navbar navbar-expand-lg navbar-light fixed-top shadow-sm" id="mainNav">
                <div className="container px-5">
                    <a className="navbar-brand fw-bold" href="/home" onClick={navBarRedirect}>Polo Cigars</a>
                    <button onClick={()=>{toggle!=="show"?setToggle('show'):setToggle('')}} className="navbar-toggler" type="button" data-bs-toggle="collapse" data-bs-target="#navbarResponsive" aria-controls="navbarResponsive" aria-expanded="false" aria-label="Toggle navigation">
                        Menu
                        <i className="bi-list"></i>
                    </button>
                    <div className={`collapse navbar-collapse ${toggle}`} id="navbarResponsive">
                        <ul className="navbar-nav ms-auto me-4 my-3 my-lg-0">
                            <li className="nav-item"><a className="nav-link me-lg-3" href="/home" onClick={navBarRedirect}>Home</a></li>
                            <li className="nav-item"><a className="nav-link me-lg-3" href="/aboutus" onClick={navBarRedirect}>About</a></li>
                            <li className="nav-item"><a className="nav-link me-lg-3" href="/contactus" onClick={navBarRedirect}>Contact Us</a></li>
                        </ul>
                        {/* <button className="btn btn-primary rounded-pill px-3 mb-2 mb-lg-0" data-bs-toggle="modal" data-bs-target="#feedbackModal">
                            <span className="d-flex align-items-center">
                                <i className="bi-chat-text-fill me-2"></i>
                                <span className="small">Contact Us</span>
                            </span>
                        </button> */}
                    </div>
                </div>
            </nav>
        </>
    )
}
export default Header;