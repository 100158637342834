import React from "react";
const Cta = () => {
    return (
        <section className="cta">
            <div className="cta-content">
                <div className="container px-5">
                    <h2 className="text-white display-1 lh-1 mb-4">
                        We don't stop.
                        <br />
                        Always work.
                    </h2>
                    {/* eslint-disable-next-line jsx-a11y/anchor-is-valid */}
                    {/* <a className="btn btn-outline-light py-3 px-4 rounded-pill" href="#" target="_blank">Download for free</a> */}
                </div>
            </div>
        </section>
    );
}
export default Cta;