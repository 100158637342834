import React from "react";
const MainBrand = () => {
    return (
    <>
        <header className="masthead" style={{color: '#FFF'}}>
            <div className="container px-5 text-center">
                <h1 className="fw-bolder">Benedicto</h1>
                {/* <p className="lead">The Benedicto cigar is a product of family tradition</p> */}
            </div>
        </header>
        <section>
            <div className="container px-4">
                <div className="row gx-4 justify-content-center">
                    <div className="col-lg-8">
                        <h2 style={{marginBottom: '2rem'}}>Composition</h2>
                        <p className="lead" style={{textAlign: 'justify'}}>
                            The elaboration of the Benedicto cigar consists of a delicate selection of tobacco grown in different countries of the world which are subjected to a study process, as to create a cigar, the union of the leaves must harmonize with each others, in this way it does not cause a discomfort or bad experience when smoking, words of Don Aníbal Rodríguez, father of Don Polo.
                        </p>
                        <p className="lead" style={{textAlign: 'justify'}}>
                            Within the countries that intervene providing the raw materials used for the elaboration of our product, we can point out, the United States, Nicaragua, Ecuador, Mexico, Cuba and the Dominican Republic, from which we acquire the best leaves for the filler, binder and the layer, whose workmanship is carried out by fine and highly respected craftsmen who are knowledgeable in the art of cigar making, these being natives in the municipality of Tamboril, Santiago RD.
                        </p>
                    </div>
                    <div className="col-lg-4" style={{zIndex: 1}}>
                        <div className="masthead-device-mockup" style={{marginTop: '3rem'}}>
                            {/* eslint-disable-next-line jsx-a11y/img-redundant-alt */}
                            <img src="/assets/img/header_label.png" alt="Logo image" style={{width: '100%'}} />                            
                        </div>
                    </div>
                </div>
            </div>
        </section>
        <section className="bg-light" id="services">
            <div className="container px-4">
                <div className="row gx-4 justify-content-center">
                    <div className="col-lg-4" style={{zIndex: 1}}>
                        <div className="masthead-device-mockup">
                            {/* eslint-disable-next-line jsx-a11y/img-redundant-alt */}
                            <img src="/assets/img/products/1.png" alt="Cigar image" style={{width: '100%'}} />                            
                        </div>
                    </div>
                    <div className="col-lg-8">
                        <h2 style={{marginBottom: '2rem'}}>Something without equal</h2>
                        <p className="lead" style={{textAlign: 'justify',marginBottom: '2rem'}}>
                            The attributes that make a Benedicto cigar recognizable can be noticed immediately with the rich aroma, flavor, smoothness in each puff, the character that demonstrates the maturity and quality of the tobacco leaf used for its preparation, the remarkable delicacy of its presentation These are some of the details that you can take into account when you have the pleasure of enjoying it.
                        </p>
                        <ul>
                            <li style={{marginBottom: '1rem'}}>Benedicto Connecticut wrapper is synonymous with softness with medium strength and an exquisite flavor of nuts that reflects a careful fermentation process.</li>
                            <li style={{marginBottom: '1rem'}}>Havana wrapper that wraps the filler and binder of the Benedicto Havana, shows the deep sweetness and incomparable flavor that reflects softness but with character.</li>
                            <li style={{marginBottom: '1rem'}}>Its San Andrés wrapper, "Maduro" in honor of Don Polo, provides an unparalleled flavor that emerges from the aging process as the fire ends the delicious experience that is reflected on your palate when you taste an exquisite Benedicto cigar.</li>
                        </ul>
                    </div>
                </div>
            </div>
        </section>
    </>
    );
}
export default MainBrand;