import React from "react";
const AboutUs = () => {
    return (
    <>
        <header className="masthead" style={{color: '#FFF'}}>
            <div className="container px-5 text-center">
                <h1 className="fw-bolder">About us</h1>
            </div>
        </header>
        <section>
            <div className="container px-4">
                <div className="row gx-4 justify-content-center">
                    <div className="col-lg-4" style={{zIndex: 1}}>
                        <div className="masthead-device-mockup" style={{marginTop: '8rem'}}>
                            {/* eslint-disable-next-line jsx-a11y/img-redundant-alt */}
                            <img src="/assets/img/header_label.png" alt="Cigar image" style={{width: '100%'}} />                            
                        </div>
                    </div>
                    <div className="col-lg-8">
                        <h2 style={{marginBottom: '2rem'}}>How do we start...</h2>
                        <p className="lead" style={{textAlign: 'justify'}}>
                            Company dedicated to the production, sale and distribution of cigars, Polo Cigars represented by the BENEDICTO brand, is the result of a research project started in 2006, the date on which Mr. José Miguel Rodríguez Reyes arrived in the municipality of Tamboril Santiago Dominican Republic, a place known throughout the world as the "world capital of cigars" with the intention of giving shape and perfection to a league that had been the property of the Rodríguez family for several decades.
                        </p>
                        <p className="lead" style={{textAlign: 'justify'}}>
                            The first orientations were received by Mr. Francis Santana, and through him he managed to relate to other great artisans and cigar manufacturers whose knowledge was put into practice with the refinement of the family league or Blend, created by his father Mr. Apolinar Rodríguez "Don Polo" recognized for his skills in agriculture, especially in the cultivation of tobacco since the beginning of the year 1970, in a community of the municipality of San José de las Matas called "pananao". As well as in a sector of the municipality of Moncion known as Monte higuero, motivated by this is that the pure Benedicto has as a sub-brand "origin" where an image taken in the year 1979 in which you can see "Don Polo ” riding his horse, accompanied by his wife and mother of Mr. José Miguel, Mrs. Francisca Reyes, better known as “Dońa Tuna”, in the background you can see a tobacco ranch in the style of the time.
                        </p>
                    </div>
                </div>
            </div>
        </section>
        <section className="bg-light" id="services">
            <div className="container px-4">
                <div className="row gx-4 justify-content-center">
                    <div className="col-lg-8">
                        <h2 style={{marginBottom: '2rem'}}>Rancho Benedicto</h2>
                        <p className="lead" style={{textAlign: 'justify'}}>
                            Ranch where neighbors and farmers congregated to pack and prepare tobacco.
                        </p>
                        <p className="lead" style={{textAlign: 'justify'}}>
                            Dońa Tuna was in charge of the fine selection and classification of tobacco leaves, said ranch being the inspiring image that represents it through the ring, where you can also see a beautiful plantation of Dominican Creole tobacco.
                        </p>
                    </div>
                </div>
            </div>
        </section>
    </>
    )
}
export default AboutUs;