import React from 'react';
const ShowCase = () =>{
    return(
        <section id="showCase">
            <div className="container px-4">
                <div className="row gx-1 align-items-center" style={{backgroundColor: '#000000de'}}>
                    <div className="col-lg-8 order-lg-1 mb-5 mb-lg-0">
                        <div className="container-fluid px-5">
                            <div className="row gx-5">
                                <div className="col-md-12 mb-5">
                                    <div className="text-center">
                                        <i className="bi-phone icon-feature text-gradient d-block mb-3"></i>
                                        <h3 className="font-alt main-text-color display-5" style={{marginBottom: "25px"}}>Don Polo</h3>
                                        <p className="text-white mb-0" style={{textAlign: 'justify',fontSize: '22px'}}>
                                            Benedicto cigar is a result of years of researching about the behavior and harmony on super premium tobacco, leaves grown in different countries and finally selected to create a family blend in honor of Mr. Apolinar Rodriguez "Don Polo".
                                        </p><br />
                                        <p className="text-white mb-0" style={{textAlign: 'justify',fontSize: '22px'}}>
                                            Recognized for his great knowledge and trajectory in the cultivation of tobacco that began in the early 70s, said knowledge was acquired by his father, my grandfather, Don Aníbal Rodríguez.
                                        </p><br /><br />
                                        <p className="text-white mb-0" style={{textAlign: 'justify',fontSize: '20px'}}>
                                            Lic. Jose  Miguel Rodriguez,<br /> Gerente General.
                                        </p>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="col-lg-4 order-lg-0">
                        {/* <!-- Features section device mockup--> */}
                        <div className="features-device-mockup">
                            {/* eslint-disable-next-line jsx-a11y/img-redundant-alt */}
                            <img src="/assets/img/Polo.jpeg" alt="Logo image" style={{width: '100%'}} />
                        </div>
                    </div>
                </div>
            </div>
        </section>
    );
}
export default ShowCase;