import React from "react";
const Product = () => {
    return (
    <>
        <header className="masthead" style={{color: '#FFF'}}>
            <div className="container px-5 text-center">
                <h1 className="fw-bolder">Our Products</h1>
            </div>
        </header>
        {/* <section className="product-section">
            <div className="container px-4">
                <div className="row gx-5 align-items-center justify-content-center">
                    <div className="col-md-6">
                        <div className="px-5 px-sm-0"><img className="img-fluid rounded" src="/assets/img/products/all.jpeg" alt="..." style={{width: '100%'}} /></div>
                    </div>
                </div>
            </div>
        </section> */}
        <section className="product-section">
            <div className="container px-4">
                <div className="row gx-5 align-items-center justify-content-center justify-content-lg-between">
                    <div className="col-md-7">
                        <h2 className="display-1 lh-1 mb-4" style={{textAlign: 'center'}}>Toro</h2>
                        {/* <p className="lead fw-normal text-muted mb-5 mb-lg-0" style={{textAlign: 'justify'}}>Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s, when an unknown printer took a galley of type and scrambled it to make a type specimen book.</p> */}
                    </div>
                    <div className="col-md-5">
                        <div className="px-5 px-sm-0"><img className="img-fluid rounded" src="/assets/img/products/1.png" alt="..." style={{width: '100%'}} /></div>
                    </div>
                </div>
            </div>
        </section>
        <section className="product-section">
            <div className="container px-4">
                <div className="row gx-5 align-items-center justify-content-center justify-content-lg-between">                    
                    <div className="col-md-6">
                        <div className="px-5 px-sm-0"><img className="img-fluid rounded" src="/assets/img/products/2.png" alt="..." style={{width: '100%'}} /></div>
                    </div>
                    <div className="col-md-6">
                        <h2 className="display-2 lh-1 mb-4" style={{textAlign: 'center'}}>Torpedo</h2>
                        {/* <p className="lead fw-normal text-muted mb-5 mb-lg-0" style={{textAlign: 'justify'}}>Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s, when an unknown printer took a galley of type and scrambled it to make a type specimen book.</p> */}
                    </div>
                </div>
            </div>
        </section>
        <section className="product-section">
            <div className="container px-4">
                <div className="row gx-5 align-items-center justify-content-center justify-content-lg-between">
                    <div className="col-md-7">
                        <h2 className="display-1 lh-1 mb-4" style={{textAlign: 'center'}}>Robusto</h2>
                        {/* <p className="lead fw-normal text-muted mb-5 mb-lg-0" style={{textAlign: 'justify'}}>Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s, when an unknown printer took a galley of type and scrambled it to make a type specimen book.</p> */}
                    </div>
                    <div className="col-md-5">
                        <div className="px-5 px-sm-0"><img className="img-fluid rounded" src="/assets/img/products/3.png" alt="..." style={{width: '100%'}} /></div>
                    </div>
                </div>
            </div>
        </section>
        {/* <section className="product-section">
            <div className="container px-4">
                <div className="row gx-5 align-items-center justify-content-center justify-content-lg-between">                    
                    <div className="col-md-6">
                        <div className="px-5 px-sm-0"><img className="img-fluid rounded" src="/assets/img/products/4.png" alt="..." style={{width: '100%'}} /></div>
                    </div>
                    <div className="col-md-6">
                        <h2 className="display-2 lh-1 mb-4" style={{textAlign: 'center'}}>Robusto</h2>
                    </div>
                </div>
            </div>
        </section>         */}
    </>
    )
}
export default Product;