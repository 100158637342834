import React, { useState, useEffect } from "react";
import Badge from "../Layouts/badge";
import Cta from "../Layouts/cta";
import Feature from "../Layouts/features";
import MastHead from "../Layouts/mastHead";
// import Quote from "../Layouts/quote";
import Snippet from "../Layouts/snippet";
import ShowCase from "../Layouts/showCase";
import Age from "./age";
const Home = () => {
    const [ageShowModal,setAgeShowModal] = useState(true);
    useEffect(()=>{
        const ssStr = sessionStorage.getItem('__BENEDICTO_CIGAR_AGE_S_VERIFIED_'),
            locStr = localStorage.getItem('_BENEDICTO_CIGAR_AGE_L_VERIFIED_');
        if(ssStr!==null&&parseInt(ssStr)===1){
            setAgeShowModal(false);
        } else if(locStr!==null&&parseInt(locStr)===1){
            setAgeShowModal(false);
        }
    },[]);
    return (
        <>
            <MastHead />
            <ShowCase />
            {/* <Quote /> */}
            <Feature />
            <Snippet />
            <Cta />
            <Badge />
            <Age 
                method={setAgeShowModal}
                show={ageShowModal}
             />
        </>
    );
}
export default Home;