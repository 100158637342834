import React from "react";
import { useNavigate } from 'react-router-dom';
const MastHead = () => {
    const navigate = useNavigate();
    const navBarRedirect = (e) => {
        e.preventDefault();
        const href = e.currentTarget.getAttribute('href');
        navigate(href);
    }
    const goToBrand = (e) => {
        e.preventDefault();
        navigate('/brand');
    }
    return (
        // <!-- Mashead header-->
        <header className="masthead" style={{marginBottom: '2rem'}}>
            <div className="container px-5">
                <div className="row gx-5 align-items-center">
                    <div className="col-lg-6" style={{zIndex: 1}}>
                        {/* <!-- Mashead text and app badges--> */}
                        <div className="mb-5 mb-lg-0 text-center text-lg-start">
                            <h1 className="display-1 lh-1 mb-3 text-white">Super Premium Handmade Cigars.</h1>
                            <p className="lead fw-normal mb-5 text-white">The Dominican Republic is recognized as one of the largest cigar producer countries in the world, reaching the top places in both production and quality.</p>
                            <div className="d-flex flex-column flex-lg-row align-items-center">
                                <a className="me-lg-3 mb-4 mb-lg-0" href="#!">
                                    <button type="button" className="btn btn-light app-badge main-text-color" href="/home" onClick={goToBrand}>About Benedicto</button>
                                </a>
                                <a className="me-lg-3 mb-4 mb-lg-0" href="#!">
                                    <button type="button" className="btn btn-light app-badge main-text-color" href="/products" onClick={navBarRedirect}>Our Products</button>
                                </a>
                            </div>
                        </div>
                    </div>
                    <div className="col-lg-6" style={{zIndex: 1}}>
                        <div className="masthead-device-mockup">
                            {/* eslint-disable-next-line jsx-a11y/img-redundant-alt */}
                            <img src="/assets/img/header_label.png" alt="Logo image" style={{width: '100%'}} />                            
                        </div>
                    </div>
                </div>
            </div>
        </header>
    )
}
export default MastHead;